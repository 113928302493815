import React from "react"
import {graphql} from "gatsby"
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {Col, Container, Row} from "react-bootstrap"
import {IPage} from "../types";
import {StaticImage} from "gatsby-plugin-image";
import {Header} from "../components/Header";
import Prijslijst from "../gatsby-theme-nieuwbouw/components/prijslijst";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            <Header>
                <StaticImage
                    src={'../img/ai/Binnengaats HR.jpg'}
                    aspectRatio={3}
                    alt={'artist impression'}
                    transformOptions={{
                        cropFocus: "entropy"
                    }}
                />
            </Header>
            <Container
                fluid={'lg'}
                className={'my-5 text-center text-md-left'}
            >
                <Row className={'align-items-end'}>
                    <Col md={6}>
                        <h1>Appartementen</h1>
                        <section>
                            <header>
                                <h2>
                                    Buitengewoon Binnengaats
                                </h2>
                            </header>
                            <p className={'lead'}>
                                Binnengaats bestaat in totaal uit 20 appartementen, verdeeld over drie lagen. Het zijn compacte en knusse appartementen, met vrij uitzicht op het gezamenlijke groene Kokkelhof.
                                Alle appartementen zijn 48 m2 groot en door de logische indeling wordt optimaal gebruik gemaakt van de ruimte. Volledig tegemoetkomend aan de moderne woonwensen van de toekomstige bewoners.
                            </p>
                        </section>
                        <section>
                            <header>
                                <h6>
                                    Wonen met karakter
                                </h6>
                            </header>
                            <p>
                                Stap binnen en je bevindt je in de gezellige woonkamer. In het midden van de woning vind je de keuken, die het appartement in tweeën deelt. Aan de achterkant van de appartementen is de slaapkamer gesitueerd.
                            </p>
                        </section>
                    </Col>
                    <Col md={6}>
                        <span className={'kalam'}>Kenmerken</span>
                        <ul>
                            <li>Stijlvol drielaags appartementencomplex</li>
                            <li>20 startersappartementen</li>
                            <li>Gebruiksoppervlak: 48 m2</li>
                            <li>Op het zuiden gelegen galerij met eigen terras</li>
                            <li>Inclusief deelauto, deelfietsen en deelscooter</li>
                            <li>Onderdeel van het Kokkelhof</li>
                            <li>Zelfbewoningsplicht</li>
                        </ul>
                    </Col>
                </Row>
                <StaticImage
                    src={'../img/ai/Binnengaats AI interieur.jpg'}
                    alt={'interieur'}
                    className={'my-5 image-shadow rounded'}
                    layout={'fullWidth'}
                />
                <section className={'my-5'}>
                    <p>
                        In Binnengaats hebben alle appartementen een eigen stukje terras.
                        De galerij is op het zuiden gesitueerd, dus is het ideaal om een bankje neer te zetten en te genieten van een kopje koffie in de zon of om met andere bewoners bij te kletsen.
                        Op alle appartementen geldt bovendien een zelfbewoningsverplichting. Daarmee blijft de verbinding met de buurt bestaan.
                        Een prettig ons-kent-ons gevoel, zonder dat het je opgedrongen wordt. Dat en meer typeert het woonkarakter van Binnengaats. Klinkt goed toch?!
                    </p>
                </section>
            </Container>
            <section className={'mt-5'}>
                <Row>
                    {/*
                    <Col
                    >
                        <StaticImage
                            src={'../img/ai/Binnengaats HR.jpg'}
                            alt={'voorgevel'}
                            className={'m-1 rounded'}
                            aspectRatio={547/621}
                            layout={'fullWidth'}
                        />
                    </Col>
                    */}
                    <Col md={6}>
                        <StaticImage
                            src={'../img/ai/Binnengaats - Kokkelhof HR.jpg'}
                            alt={'achtergevel'}
                            className={'rounded'}
                            aspectRatio={800/600}
                            layout={'fullWidth'}
                        />
                    </Col>
                    <Col md={6}>
                        <StaticImage
                            src={'../img/sfeer/water.png'}
                            alt={'water'}
                            className={'rounded'}
                            aspectRatio={800/600}
                            layout={'fullWidth'}
                        />
                    </Col>
                </Row>
            </section>
            <section
                className={'py-5'}
                style={{
                    backgroundColor: '#e1e8e3'
                }}
            >
                <header>
                    <h2
                        className={'text-center'}
                    >
                        Indeling
                    </h2>
                </header>
                <div className={'d-flex align-items-center p-5'}>
                    <StaticImage className={'mx-auto'} height={800} src={'../img/plattegrond.png'} alt={'plattegrond'} />
                </div>
            </section>
            <Container className={'my-5'} as={'section'}>
                <header>
                    <h2>
                        Beschikbaarheid
                    </h2>
                </header>
                <Prijslijst />
            </Container>
            <Container className={'my-5'} as={'section'}>
                <header>
                    <h2 className={'text-center'}>
                        Situatie
                    </h2>
                </header>
                <StaticImage
                    src={'../img/situatie.png'}
                    alt={'situatie'}
                    className={'rounded'}
                />
            </Container>
        </Page>
    );
}

interface PageQueryData {
    file: {
        childMarkdownRemark: IPage
    }
}

export const pageQuery = graphql`
    query {
        file(name: {eq: "appartementen"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        kavelmap: file(relativePath: {eq: "situatie.png"}) {
            id
            publicURL
            childImageSharp {
                original {
                    height
                    width
                }
            }
        }
    }
`

